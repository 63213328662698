'use client';

import { FC } from 'react';
import { Player } from '@spikemark/rest-api';
import { Mascot, MascotProps, variants } from './mascot';
import { cn } from '../utils/cn';

type AvatarVariant = 'small' | 'medium' | 'large' | 'xlarge';

const schoolLogoSizeVariants: Record<AvatarVariant, MascotProps['variant']> = {
  xlarge: 'medium',
  large: 'badge',
  medium: 'small',
  small: 'tiny',
};

export type AvatarProps = Pick<
  Player,
  'userPicture' | 'playerName' | 'schoolLogoThumbnail' | 'schoolName'
> & {
  variant?: AvatarVariant;
};

export const Avatar: FC<AvatarProps> = ({
  playerName,
  schoolName,
  schoolLogoThumbnail,
  userPicture,
  variant = 'medium',
}) => {
  const { width, height } = variants[variant];

  return (
    <div className="relative leading-none" style={{ width: width + 4, height: height + 4 }}>
      <Mascot variant={variant} path={userPicture} alt={playerName} displayMode="player" />
      <Mascot
        variant={schoolLogoSizeVariants[variant]}
        path={schoolLogoThumbnail}
        alt={schoolName}
        className={cn('absolute', {
          'bottom-1.5 -right-3.5': variant !== 'small',
          '-bottom-1 -right-1': variant === 'small',
        })}
        displayMode="team"
        border
      />
    </div>
  );
};
